import Vue from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: () => import("../view/welcome/index.vue"),
  },
  {
    path: "/operateProcess",
    name: "OperateProcess",
    component: () => import("../view/operateProcess/index.vue"),
  },
  {
    path: "/appointment",
    name: "Appointment",
    component: () => import("../view/appointment/index.vue"),
  },
  {
    path: "/interviewee",
    name: "Interviewee",
    component: () => import("../view/interviewee/index.vue"),
  },
  {
    path: "/appointmentInfo",
    name: "AppointmentInfo",
    component: () => import("../view/appointmentInfo/index.vue"),
  },
  {
    path: "/myInfo",
    name: "MyInfo",
    component: () => import("../view/my/myInfo.vue"),
  },
  {
    path: "/myInfoDetail",
    name: "MyInfoDetail",
    component: () => import("../view/my/myInfoDetail.vue"),
  },
  {
    path: "/safetyCivilization",
    name: "SafetyCivilization",
    component: () => import("../view/safetyCivilization/index.vue"),
  },
  {
    path: "/safeTest",
    name: "SafeTest",
    component: () => import("../view/safeTest/index.vue"),
  },
  {
    path: "/privacyAgree",
    name: "PrivacyAgree",
    component: () => import("../view/privacyAgree/index.vue"),
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
