import { createApp } from "vue";
import App from "./App.vue";
import Vant from "vant";
import router from "./router/index";
import "amfe-flexible";
// 2. 引入组件样式
import "vant/lib/index.css";
// 控制台调试
// import VConsole from "vconsole";
// const vConsole = new VConsole();

createApp(App).use(Vant).use(router).mount("#app"); //使用vant
